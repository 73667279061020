export const technologies = [
  {
    name: "Java",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original-wordmark.svg",
    proficiency: 80,
  },
  {
    name: "Angular",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg",
    proficiency: 60,
  },
  {
    name: "React",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg",
    proficiency: 80,
  },
  {
    name: "C++",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg",
    proficiency: 50,
  },
  {
    name: "JavaScript",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-plain.svg",
    proficiency: 90,
  },
  {
    name: "HTML",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
    proficiency: 90,
  },
  {
    name: "CSS",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
    proficiency: 90,
  },
  {
    name: "Python",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original-wordmark.svg",
    proficiency: 50,
  },
  {
    name: "GraphQL",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/graphql/graphql-plain-wordmark.svg",
    proficiency: 70,
  },
  {
    name: "SQL",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg",
    proficiency: 75,
  },
  {
    name: "MongoDB",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg",
    proficiency: 70,
  },
  {
    name: "Azure",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/azure/azure-original.svg",
    proficiency: 60,
  },
  {
    name: "REST API",
    imgSrc: "https://cdn.svgapi.com/vector/10073/api-page.svg",
    proficiency: 80,
  },
  {
    name: "Salesforce",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/salesforce/salesforce-original.svg",
    proficiency: 40,
  },
  // {
  //   name: "SAP",
  //   imgSrc:
  //     "https://upload.wikimedia.org/wikipedia/commons/5/59/SAP_2011_logo.svg",
  //   proficiency: 65,
  // },
  {
    name: "Tailwind CSS",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original.svg",
    proficiency: 65,
  },
  {
    name: "Git",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original-wordmark.svg",
    proficiency: 70,
  },
  {
    name: "GitHub",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original-wordmark.svg",
    proficiency: 70,
  },
  {
    name: "Docker",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original-wordmark.svg",
  },
  {
    name: "VS code",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original-wordmark.svg",
  },
  {
    name: "Intellij",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/intellij/intellij-original.svg",
  },
  {
    name: "Bash",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bash/bash-plain.svg",
  },
  {
    name: "Microsoft SQL server",
    imgSrc:
      "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/microsoftsqlserver/microsoftsqlserver-original-wordmark.svg",
  },
  {
    name:"Vue.js",
    imgSrc:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vuejs/vuejs-original-wordmark.svg"
  },
  {name:"Bootstrap",
    imgSrc:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bootstrap/bootstrap-original.svg"
  }
];
