import React from "react";
import { motion } from "framer-motion";
import boyImage from "../Assets/A Boy 2.jpg";
import { GraduationCap, Briefcase } from "lucide-react";

const timelineData = [
  {
    type: "education",
    title: "Bachelor of Engineering in Computer Engineering",
    institution: "Pune Institute of Computer Technology",
    duration: "2017 - 2021",
    progress: 100,
  },
  {
    type: "experience",
    title: "Technical Service Trainee",
    institution: "Fujitsu Consulting India Pvt. Ltd.",
    duration: "2022 - Present",
    progress: 75,
  },
];

const About = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        staggerChildren: 0.3,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
  };

  const timelineVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const progressBarVariants = {
    hidden: { width: 0 },
    visible: (progress) => ({
      width: `${progress}%`,
      transition: { duration: 1.5 },
    }),
  };

  const iconVariants = {
    hidden: { left: 0 },
    visible: (progress) => ({
      left: `${progress}%`,
      transition: { duration: 1.5 },
    }),
  };

  return (
    <motion.section
      id="about"
      className="min-h-screen flex items-center justify-center bg-gradient-to-br py-20 bg-gray-800"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false, amount: 0.3 }}
      variants={containerVariants}
    >
      <div className="container mx-auto px-4 sm:px-6 md:px-12">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-center text-white"
          variants={textVariants}
        >
          About Me
        </motion.h2>
        <div className="container bg-gray-700 py-8 px-6 sm:py-12 rounded-xl shadow-lg">
          <div className="flex flex-col md:flex-row items-center">
            <motion.div
              className="w-full md:w-1/2 mb-6 md:mb-0"
              variants={imageVariants}
            >
              <img
                src={boyImage}
                alt="About me"
                className="rounded-full shadow-lg mx-auto w-40 h-40 sm:w-48 sm:h-48 md:w-60 md:h-60"
              />
            </motion.div>

            <motion.div
              className="w-full md:w-1/2 md:pl-12"
              variants={textVariants}
            >
              <p className="text-base sm:text-lg text-white leading-relaxed">
                A Computer Engineering graduate skilled in JavaScript, Java,
                with experience in ReactJS, Angular, and cloud platforms like
                Azure. Expertise in API development, database management, and
                automation tools.
              </p>
              {/* <p className="text-base sm:text-lg text-white leading-relaxed mt-4">
                Fusce porta tincidunt justo, non tincidunt felis sagittis eget.
                Vivamus nec commodo turpis. Donec scelerisque condimentum
                mauris, sed tempor arcu ultrices eu.
              </p> */}
            </motion.div>
          </div>
        </div>

        {/* Education & Experience Timeline Section */}

        <div className="max-w-3xl mx-auto bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-center text-gray-100 mb-12">
            Education & Experience
          </h1>

          <div className="relative">
            {/* Vertical line */}
            <div className="absolute left-1/2 transform -translate-x-1/2 w-1 h-full bg-gray-200 z-0"></div>

            {timelineData.map((item, index) => (
              <motion.div
                key={index}
                className="mb-12 flex justify-between items-center w-full"
                variants={timelineVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.3 }}
              >
                <div
                  className={`w-5/12 ${
                    index % 2 === 0 ? "text-right pr-8" : "order-1 pl-8"
                  }`}
                >
                  <h3 className="text-lg font-semibold text-gray-100">
                    {item.title}
                  </h3>
                  <p className="text-sm text-gray-200">{item.institution}</p>
                  <p className="text-xs text-gray-300">{item.duration}</p>
                </div>

                <div className="z-10 w-10 h-10 rounded-full bg-white border-4 border-gray-200 flex items-center justify-center">
                  <div
                    className={`w-3 h-3 rounded-full ${
                      item.type === "education" ? "bg-blue-500" : "bg-green-500"
                    }`}
                  ></div>
                </div>

                <div
                  className={`w-5/12 ${
                    index % 2 === 0 ? "order-1 pl-8" : "pr-8"
                  }`}
                >
                  <div className="relative">
                    <motion.div
                      className="h-4 bg-gray-200 rounded-full overflow-hidden"
                      initial={{ width: 0 }}
                      animate={{ width: "100%" }}
                      transition={{ duration: 1, delay: index * 0.2 + 0.5 }}
                    >
                      <motion.div
                        className={`h-full ${
                          item.type === "education"
                            ? "bg-blue-500"
                            : "bg-green-500"
                        }`}
                        variants={progressBarVariants}
                        custom={item.progress}
                        initial="hidden"
                        whileInView="visible"
                      ></motion.div>
                    </motion.div>
                    <motion.div
                      className="absolute top-1/2 transform -translate-y-1/2"
                      variants={iconVariants}
                      custom={item.progress}
                      initial="hidden"
                      whileInView="visible"
                    >
                      <div
                        className={`w-8 h-8 rounded-full ${
                          item.type === "education"
                            ? "bg-blue-500"
                            : "bg-green-500"
                        } flex items-center justify-center -ml-4`}
                      >
                        {item.type === "education" ? (
                          <GraduationCap className="w-5 h-5 text-white" />
                        ) : (
                          <Briefcase className="w-5 h-5 text-white" />
                        )}
                      </div>
                    </motion.div>
                  </div>
                  <p className="text-sm text-gray-200 mt-2">
                    {item.progress}% Complete
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
