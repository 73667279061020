import React, { useState } from "react";
import { FaExternalLinkAlt, FaGithub } from "react-icons/fa";
import { motion } from "framer-motion";
import { video } from "framer-motion/client";

const projects = [
  {
    title: "Project Task Management System",
    description:
      "Developed a full-stack project management system using ReactJS and Spring Boot, incorporating features like project creation, user invitations, and real-time chat. I ensured robust security with Spring Security and JWT, while employing Tailwind CSS for a modern and responsive design. This project enhances collaboration and scalability, providing teams with an efficient tool for managing tasks.",
    // videoId: "FAxay2A9tCs",
    previewImage: require("../Assets/Project Management Sytem.webp"),
    video: require("../Assets/Project Management Sytem.webp"),
    github: "https://github.com/gaikwadashutosh35/ProjectManagementSystem",
    // live: "https://project-one.live",
    // Description : The video guides viewers through building a full-stack project management system using ReactJS and Spring Boot, featuring functionalities like project creation, user invitations, chat, and subscription plans.

// Highlights
// 🚀 Full-stack project management system development.
// 📊 Features include project creation, issue management, and communication.
// 📧 User invitations via email for project collaboration.
// 💳 Subscription plans for project limits (free, monthly, annually).
// 🔒 Utilizes Spring Security and JWT for authentication.
// 💬 Real-time chat functionality for team communication.
// 🛠️ Implemented using popular tech stack: React, Spring Boot, Tailwind CSS.
// Key Insights
// 💻 The project emphasizes the significance of both front-end (React) and back-end (Spring Boot) technologies working in unison to create a seamless user experience.
// 🔗 User invitations enhance collaboration by allowing team members to join projects easily, fostering teamwork and communication.
// 💵 Subscription plans ensure scalability, enabling users to manage project limits based on their chosen plan, which can drive greater user engagement.
// 🔒 Security is paramount; implementing JWT and Spring Security helps protect user data and restrict access to authenticated users.
// 📦 The use of Tailwind CSS and Shadcn UI promotes a modern and responsive design, improving overall aesthetics and usability.
// 📈 Real-time chat features facilitate instant communication, crucial for project management and issue resolution.
// 🔍 The ability to filter and search projects by various criteria adds to the system’s usability and efficiency, making it easier for users to manage their tasks.
// Summary

  },
  {
    title: "Crypto Trading Platform",
    description:
      "Created a full-stack cryptocurrency trading platform with Spring Boot and React, featuring wallet management, two-factor authentication, and an AI chatbot for real-time data. The project includes CoinGecko API integration for market information and promotes community engagement through giveaways",
    videoId: "o2Co_kC9VMU",
    previewImage: require("../Assets/Trading Platform.webp"),
    github: "https://github.com/gaikwadashutosh35/Crypto-Trading-Platform",
    live: "https://project-two.live",
    //     description:"In this video, we build a full-stack trading platform for cryptocurrencies using Spring Boot and React, featuring wallet management, two-factor authentication, and an AI chatbot for real-time crypto data.

    // Highlights
    // 🚀 Full-stack trading platform development.
    // 💰 Wallet management for deposits and withdrawals.
    // 🔐 Implementation of two-factor authentication.
    // 🤖 AI chatbot for real-time cryptocurrency queries.
    // 📈 Integration with CoinGecko API for market data.
    // 🎁 Free source code giveaway for best comments.
    // 🆓 Three-month JetBrains membership with coupon code.
    // Key Insights
    // 🌐 The project combines Spring Boot for backend and React for frontend, showcasing modern web development practices and technologies.
    // 💳 Users can easily manage their crypto wallets, enhancing user experience and engagement through intuitive design.
    // 🔒 Two-factor authentication increases the security of the trading platform, a crucial factor in today’s cybersecurity landscape.
    // 💬 An AI chatbot powered by external APIs facilitates user interaction, providing instant responses to cryptocurrency inquiries.
    // 📊 Real-time data integration through CoinGecko API emphasizes the importance of up-to-date information in trading platforms.
    // 🎉 Community engagement through giveaways fosters a sense of connection and appreciation among viewers.
    // 💡 The project serves as a comprehensive learning resource for developers looking to enhance their full-stack development skills."
  },
  // Add more projects as needed
];

const Projects = () => {
  const [videoIndex, setVideoIndex] = useState(null);
  const containerVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.4, // Delay between cards
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  const cardVariants = (index) => ({
    hidden: {
      opacity: 0,
      // x: index % 2 === 0 ? -100 : 100, // Move from left if even index, right if odd index
      x: index % 2 === 0 ? -40 : 40, // Reduce movement to 50px from each side
      scale: 0.9,
    },
    visible: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
    hover: {
      scale: 1.05,
      rotateY: 5, // Slight 3D rotation for more realism
      boxShadow: "0px 20px 40px rgba(0,0,0,0.4)",
      transition: { duration: 0.3 },
    },
  });
  const videoVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
    hover: { scale: 1.1 },
  };
  // Staggered animation for the text (title, description, icons)
  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const handlePlayClick = (index) => {
    setVideoIndex(index); // Set the index of the video to be played
  };

  return (
    <motion.section
      id="projects"
      className="min-h-screen flex items-center justify-center bg-gradient-to-br py-20 bg-gray-900"
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false, amount: 0.3 }}
    >
      <div className="container mx-auto px-6 md:px-12">
        <h2 className="text-3xl sm:text-4xl font-bold mb-12 text-center">
          My Projects
        </h2>
        <div className="grid gap-12 md:grid-cols-2">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              className="bg-gray-800 rounded-lg overflow-hidden shadow-lg"
              variants={cardVariants(index)}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.2 }}
              whileHover="hover"
            >
              <div className="relative w-full h-64 flex items-center justify-center">
                {videoIndex === index ? (
                  <motion.div
                    className="w-auto h-auto"
                    variants={videoVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.2 }}
                    whileHover="hover"
                  >
                    <video
                      width="100%"
                      height="100%"
                      controls
                      autoPlay
                      muted
                      className="object-cover"
                    >
                      <source src={project.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </motion.div>
                ) : (
                  <img
                    src={project.previewImage}
                    alt={project.title}
                    className="w-auto h-44 md:h-60 object-cover cursor-pointer"
                    onClick={() => handlePlayClick(index)}
                  />
                )}
              </div>
              <div className="p-6">
                <motion.h3
                  className="text-2xl font-semibold mb-4 text-white"
                  variants={textVariants}
                >
                  {project.title}
                </motion.h3>
                <motion.p
                  className="text-gray-400 mb-6"
                  variants={textVariants}
                  transition={{ delay: 0.2 }}
                >
                  {project.description}
                </motion.p>
                <motion.div
                  className="flex space-x-4"
                  variants={textVariants}
                  transition={{ delay: 0.4 }}
                >
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-white transition"
                  >
                    <FaGithub size={24} />
                  </a>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Projects;
