import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { img } from "framer-motion/client";
import { technologies } from "./Technologies";
// List of tech logos, you can add as many as needed

// Animation variants for the floating effect
// const floatVariants = {
//   animate: {
//     y: [0, 20, 0], // Move up and down
//     transition: {
//       duration: 6, // Duration of the animation
//       repeat: Infinity, // Keep repeating
//       ease: "easeInOut",
//     },
//   },
// };
// Text animation
const textVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.8, ease: "easeInOut" },
  },
};

const floatVariants = {
  initial: {
    opacity: 1,
    scale: 1,
  },
  animate: {
    opacity: 0, // Fade out
    scale: 0.5, // Shrink the images as they approach the center
    transition: {
      duration: 3, // Time it takes to shrink and fade out
      ease: "easeInOut",
    },
  },
};

const imageSize = 14; // Size of the logos in percentage
const flowDuration = 2; // Duration for each logo's movement
const circleLogosCount = 6; // Number of positions in the circle
const logosPerBatch = 6;
const transitionDelayBetweenSets = 0.5; // Delay between sets for smooth transition

// const Background = () => {
//   const [circlePositions, setCirclePositions] = useState([]); // Circular positions for 8 logos
//   const [isAnimationStarted, setIsAnimationStarted] = useState(false); // Control animation
//   const [currentLogoIndex, setCurrentLogoIndex] = useState(0); // Track the current logo

//   // Function to calculate positions for the 8 logos in a circle
//   const calculateCirclePositions = () => {
//     const radiusX = 40; // Horizontal radius for circular shape
//     const radiusY = 40; // Vertical radius for circular shape
//     const newPositions = [];

//     for (let i = 0; i < circleLogosCount; i++) {
//       const angle = (i / circleLogosCount) * 2 * Math.PI; // Calculate angle for each logo
//       const top = `${50 + radiusY * Math.sin(angle)}%`; // Top position
//       const left = `${45 + radiusX * Math.cos(angle)}%`; // Left position
//       newPositions.push({ top, left });
//     }

//     return newPositions;
//   };

//   useEffect(() => {
//     const positions = calculateCirclePositions(); // Calculate circle positions
//     setCirclePositions(positions);
//     setIsAnimationStarted(true); // Start animation
//   }, []);

//   // Automatically cycle through the logos in the array, one at a time
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % technologies.length); // Move to the next logo
//     }, flowDuration * 500); // Change logo every `flowDuration` seconds

//     return () => clearInterval(interval); // Clear interval on unmount
//   }, []);

//   return (
//     <div className="absolute inset-0 z-0 overflow-hidden flex items-center justify-center">
//       {/* Flowing logos, 8 logos simultaneously from their respective positions */}
//       {circlePositions.map((position, positionIndex) => {
//         const { top, left } = position; // Get circular position

//         // Determine which batch of logos is currently being shown
//         const currentBatchIndex = Math.floor(currentLogoIndex / logosPerBatch);
//         const logoIndex =
//           (currentBatchIndex * logosPerBatch + positionIndex) %
//           technologies.length; // Select logo from the array

//         return (
//           <motion.img
//             key={positionIndex}
//             src={technologies[logoIndex].imgSrc} // Logo from the array
//             alt={technologies[logoIndex].name}
//             className="absolute opacity-80"
//             style={{
//               top: top,
//               left: left,
//               width: `${imageSize}%`,
//               height: `${imageSize}%`,
//             }}
//             initial={{ opacity: 1, scale: 1 }} // Start fully visible
//             animate={
//               isAnimationStarted
//                 ? {
//                     top: "50%", // Move toward the center
//                     left: "45%",
//                     opacity: 0, // Fade out as it moves inward
//                     scale: 0.5, // Shrink as it moves inward
//                   }
//                 : {}
//             }
//             transition={{
//               duration: flowDuration, // Duration of the inward flow
//               ease: "easeInOut",
//               repeat: Infinity, // Loop continuously
//               repeatType: "loop", // Ensure the animation loops smoothly
//               delay: 0, // No delay, all logos flow inward at the same time
//             }}
//           />
//         );
//       })}

//       {/* This effect cycles through sets of logos every flowDuration * 2 seconds */}
//       {useEffect(() => {
//         const interval = setInterval(() => {
//           // Move to the next set of 8 logos
//           setCurrentLogoIndex(
//             (prevIndex) => (prevIndex + logosPerBatch) % technologies.length
//           );
//         }, flowDuration * 1000); // Trigger every `flowDuration` seconds

//         return () => clearInterval(interval); // Clean up on unmount
//       }, [])}
//     </div>
//   );
// };

//Rotate logos
// const Background = () => {
//   const [circlePositions, setCirclePositions] = useState([]); // Circular positions for logos
//   const [isAnimationStarted, setIsAnimationStarted] = useState(false); // Control animation
//   const [currentLogoIndex, setCurrentLogoIndex] = useState(0); // Track the current logo

//   // Function to calculate positions for the logos in a circle
//   const calculateCirclePositions = () => {
//     const radiusX = 40; // Horizontal radius for circular shape
//     const radiusY = 40; // Vertical radius for circular shape
//     const newPositions = [];

//     for (let i = 0; i < circleLogosCount; i++) {
//       const angle = (i / circleLogosCount) * 2 * Math.PI; // Calculate angle for each logo
//       const top = `${50 + radiusY * Math.sin(angle)}%`; // Top position
//       const left = `${50 + radiusX * Math.cos(angle)}%`; // Left position
//       newPositions.push({ top, left });
//     }

//     return newPositions;
//   };

//   useEffect(() => {
//     const positions = calculateCirclePositions(); // Calculate circle positions
//     setCirclePositions(positions);
//     setIsAnimationStarted(true); // Start animation
//   }, []);

//   // Automatically cycle through the logos in the array
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentLogoIndex(
//         (prevIndex) => (prevIndex + logosPerBatch) % technologies.length
//       ); // Move to the next batch of logos
//     }, (flowDuration + transitionDelayBetweenSets) * 1000); // Add a delay between transitions for smooth effect

//     return () => clearInterval(interval); // Cleanup on unmount
//   }, [currentLogoIndex]); // Only depend on currentLogoIndex to trigger re-renders

//   return (
//     <div className="absolute inset-0 z-0 overflow-hidden flex items-center justify-center">
//       {/* Flowing logos, 6 logos simultaneously from their respective positions */}
//       {circlePositions.map((position, positionIndex) => {
//         const { top, left } = position; // Get circular position

//         // Determine which batch of logos is currently being shown
//         const currentBatchIndex = Math.floor(currentLogoIndex / logosPerBatch);
//         const logoIndex =
//           (currentBatchIndex * logosPerBatch + positionIndex) %
//           technologies.length; // Select logo from the array

//         return (
//           <motion.img
//             key={positionIndex}
//             src={technologies[logoIndex].imgSrc} // Logo from the array
//             alt={technologies[logoIndex].name}
//             className="absolute opacity-80"
//             style={{
//               top: top,
//               left: left,
//               width: `${imageSize}%`,
//               height: `${imageSize}%`,
//             }}
//             initial={{ opacity: 1, scale: 1, rotate: 0 }} // Start fully visible
//             animate={
//               isAnimationStarted
//                 ? {
//                     top: "50%", // Move toward the center
//                     left: "50%",
//                     opacity: 0, // Fade out as it moves inward
//                     scale: 0.5, // Shrink as it moves inward
//                     rotate: 360, // Add rotation for a dynamic effect
//                   }
//                 : {}
//             }
//             transition={{
//               duration: flowDuration, // Duration of the inward flow
//               ease: "easeInOut",
//               repeat: Infinity, // Loop continuously
//               repeatType: "loop", // Ensure the animation loops smoothly
//               delay: positionIndex * (transitionDelayBetweenSets / 2), // Stagger delay for cascading effect
//             }}
//           />
//         );
//       })}
//     </div>
//   );
// };

const Background = () => {
  const [circlePositions, setCirclePositions] = useState([]); // Circular positions for logos
  const [isAnimationStarted, setIsAnimationStarted] = useState(false); // Control animation
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0); // Track the current logo

  // Function to calculate positions for the logos in a circle
  const calculateCirclePositions = () => {
    const radiusX = 35; // Horizontal radius for circular shape
    const radiusY = 35; // Vertical radius for circular shape
    const newPositions = [];

    for (let i = 0; i < circleLogosCount; i++) {
      const angle = (i / circleLogosCount) * 2 * Math.PI; // Calculate angle for each logo
      const top = `${50 + radiusY * Math.sin(angle)}%`; // Top position
      const left = `${42 + radiusX * Math.cos(angle)}%`; // Left position
      newPositions.push({ top, left });
    }

    return newPositions;
  };

  useEffect(() => {
    const positions = calculateCirclePositions(); // Calculate circle positions
    setCirclePositions(positions);
    setIsAnimationStarted(true); // Start animation
  }, []);

  // Automatically cycle through the logos in the array
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLogoIndex(
        (prevIndex) => (prevIndex + logosPerBatch) % technologies.length
      ); // Move to the next batch of logos
    }, (flowDuration + transitionDelayBetweenSets) * 1000); // Add a delay between transitions for smooth effect

    return () => clearInterval(interval); // Cleanup on unmount
  }, [currentLogoIndex]); // Only depend on currentLogoIndex to trigger re-renders

  return (
    <div className="absolute inset-0 z-0 overflow-hidden flex items-center justify-center">
      {/* Flowing logos, 6 logos simultaneously from their respective positions */}
      {circlePositions.map((position, positionIndex) => {
        const { top, left } = position; // Get circular position

        // Determine which batch of logos is currently being shown
        const currentBatchIndex = Math.floor(currentLogoIndex / logosPerBatch);
        const logoIndex =
          (currentBatchIndex * logosPerBatch + positionIndex) %
          technologies.length; // Select logo from the array

        return (
          <motion.img
            key={positionIndex}
            src={technologies[logoIndex].imgSrc} // Logo from the array
            alt={technologies[logoIndex].name}
            className="absolute opacity-80"
            style={{
              top: top,
              left: left,
              width: `${imageSize}%`,
              height: `${imageSize}%`,
            }}
            initial={{ opacity: 0.5, scale: 1, rotate: 0, y: 0 }} // Start fully visible
            animate={
              isAnimationStarted
                ? {
                    top: "50%", // Move toward the center
                    left: "45%",
                    opacity: 0, // Fade out as it moves inward
                    scale: 0.5, // Shrink as it moves inward
                    rotate: 360, // Add rotation for a dynamic effect
                    y: -20, // Slight upward movement for depth
                  }
                : {}
            }
            transition={{
              duration: flowDuration, // Duration of the inward flow
              ease: [0.68, -0.55, 0.27, 1.55], // Cubic Bezier for smoother transition
              repeat: Infinity, // Loop continuously
              repeatType: "loop", // Ensure the animation loops smoothly
              delay: positionIndex * (transitionDelayBetweenSets / 2), // Stagger delay for cascading effect
            }}
          />
        );
      })}
    </div>
  );
};
export default Background;
